import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'

import logo1 from '../images/logos/1.png'
import logo2 from '../images/logos/2.png'
import logo3 from '../images/logos/3.png'
import logo4 from '../images/logos/4.png'
import logo5 from '../images/logos/5.png'
import logo6 from '../images/logos/6.png'
import logo7 from '../images/logos/7.png'
import logo8 from '../images/logos/8.png'
import logo9 from '../images/logos/9.png'
import logo10 from '../images/logos/10.png'
import logo11 from '../images/logos/11.png'
import logo12 from '../images/logos/12.png'
import logo13 from '../images/logos/13.png'
import logo14 from '../images/logos/14.png'
import logo15 from '../images/logos/15.png'
import logo16 from '../images/logos/16.png'
import logo17 from '../images/logos/17.png'
import logo18 from '../images/logos/18.png'
import logo19 from '../images/logos/19.png'
import logo20 from '../images/logos/20.png'
import logo21 from '../images/logos/21.png'
import logo22 from '../images/logos/22.png'
import logo23 from '../images/logos/23.png'

const Standings2019Page = () => (
  <Layout>
    <div className="standings__wrapper">
      <ul>
        <li><Link to="/" className="button ">Nazad</Link></li>
      </ul>

      <div className="logos">
        <div className="logo-wrapper">
          <img src={ logo1 } alt="" className="logo"/>
        </div>
        <div className="logo-wrapper">
          <img src={ logo2 } alt="" className="logo"/>
        </div>
        <div className="logo-wrapper">
          <img src={ logo3 } alt="" className="logo"/>
        </div>
        <div className="logo-wrapper">
          <img src={ logo4 } alt="" className="logo"/>
        </div>
        <div className="logo-wrapper">
          <img src={ logo5 } alt="" className="logo"/>
        </div>
        <div className="logo-wrapper logo-wrapper--light">
          <img src={ logo6 } alt="" className="logo"/>
        </div>
        <div className="logo-wrapper">
          <img src={ logo7 } alt="" className="logo"/>
        </div>
        <div className="logo-wrapper logo-wrapper--light">
          <img src={ logo8 } alt="" className="logo"/>
        </div>
        <div className="logo-wrapper logo-wrapper--light">
          <img src={ logo9 } alt="" className="logo"/>
        </div>
        <div className="logo-wrapper">
          <img src={ logo10 } alt="" className="logo"/>
        </div>
        <div className="logo-wrapper">
          <img src={ logo11 } alt="" className="logo"/>
        </div>
        <div className="logo-wrapper">
          <img src={ logo12 } alt="" className="logo"/>
        </div>
        <div className="logo-wrapper">
          <img src={ logo13 } alt="" className="logo"/>
        </div>
        <div className="logo-wrapper">
          <img src={ logo14 } alt="" className="logo"/>
        </div>
        <div className="logo-wrapper">
          <img src={ logo15 } alt="" className="logo"/>
        </div>
        <div className="logo-wrapper logo-wrapper--light">
          <img src={ logo16 } alt="" className="logo"/>
        </div>
        <div className="logo-wrapper">
          <img src={ logo17 } alt="" className="logo"/>
        </div>
        <div className="logo-wrapper logo-wrapper--light">
          <img src={ logo18 } alt="" className="logo"/>
        </div>
        <div className="logo-wrapper">
          <img src={ logo19 } alt="" className="logo"/>
        </div>
        <div className="logo-wrapper">
          <img src={ logo20 } alt="" className="logo"/>
        </div>
        <div className="logo-wrapper logo-wrapper--light">
          <img src={ logo21 } alt="" className="logo"/>
        </div>
        <div className="logo-wrapper logo-wrapper--light">
          <img src={ logo22 } alt="" className="logo"/>
        </div>
        <div className="logo-wrapper">
          <img src={ logo23 } alt="" className="logo"/>
        </div>
      </div>

      <div className="wrapper__groups">
        <div className="group">
            <div className="team">
                <div className="team-name label">Group A</div>
                <div className="team-w">Win</div>
                <div className="team-l">Loss</div>
            </div>
            <div className="team">
                <div className="team-name">Ardeo Solutions</div>
                <div className="team-w">4</div>
                <div className="team-l">1</div>
            </div>
            <div className="team">
                <div className="team-name">Catena Media</div>
                <div className="team-w">4</div>
                <div className="team-l">1</div>
            </div>
            <div className="team">
                <div className="team-name">Couch Coach</div>
                <div className="team-w">3</div>
                <div className="team-l">2</div>
            </div>
            <div className="team">
                <div className="team-name">MSG</div>
                <div className="team-w">2</div>
                <div className="team-l">3</div>
            </div>
            <div className="team">
                <div className="team-name">PHP Srbija</div>
                <div className="team-w">1</div>
                <div className="team-l">4</div>
            </div>
            <div className="team">
                <div className="team-name">Glasnik</div>
                <div className="team-w">1</div>
                <div className="team-l">4</div>
            </div>
        </div>
        <div className="group">
            <div className="team">
                <div className="team-name label">Group B</div>
                <div className="team-w">Win</div>
                <div className="team-l">Loss</div>
            </div>
            <div className="team">
                <div className="team-name">Tieto</div>
                <div className="team-w">5</div>
                <div className="team-l">0</div>
            </div>
            <div className="team">
                <div className="team-name">Lalini Medjedi</div>
                <div className="team-w">4</div>
                <div className="team-l">1</div>
            </div>
            <div className="team">
                <div className="team-name">Gde ti je papirić</div>
                <div className="team-w">3</div>
                <div className="team-l">2</div>
            </div>
            <div className="team">
                <div className="team-name">BSW</div>
                <div className="team-w">2</div>
                <div className="team-l">3</div>
            </div>
            <div className="team">
                <div className="team-name">Zen Basket</div>
                <div className="team-w">1</div>
                <div className="team-l">4</div>
            </div>
            <div className="team">
                <div className="team-name">NF Innova</div>
                <div className="team-w">0</div>
                <div className="team-l">5</div>
            </div>
        </div>
        <div className="group">
            <div className="team">
                <div className="team-name label">Group C</div>
                <div className="team-w">Win</div>
                <div className="team-l">Loss</div>
            </div>
            <div className="team">
                <div className="team-name">Taraba</div>
                <div className="team-w">4</div>
                <div className="team-l">0</div>
            </div>
            <div className="team">
                <div className="team-name">SoGe</div>
                <div className="team-w">3</div>
                <div className="team-l">1</div>
            </div>
            <div className="team">
                <div className="team-name">Holycode</div>
                <div className="team-w">2</div>
                <div className="team-l">2</div>
            </div>
            <div className="team">
                <div className="team-name">BOS</div>
                <div className="team-w">1</div>
                <div className="team-l">3</div>
            </div>
            <div className="team">
                <div className="team-name">VP</div>
                <div className="team-w">0</div>
                <div className="team-l">4</div>
            </div>
        </div>
        <div className="group">
            <div className="team">
                <div className="team-name label">Group D</div>
                <div className="team-w">Win</div>
                <div className="team-l">Loss</div>
            </div>
            <div className="team">
                <div className="team-name">SWG</div>
                <div className="team-w">4</div>
                <div className="team-l">0</div>
            </div>
            <div className="team">
                <div className="team-name">Nesalomivi</div>
                <div className="team-w">3</div>
                <div className="team-l">1</div>
            </div>
            <div className="team">
                <div className="team-name">Fabrika</div>
                <div className="team-w">2</div>
                <div className="team-l">2</div>
            </div>
            <div className="team">
                <div className="team-name">Abstract</div>
                <div className="team-w">1</div>
                <div className="team-l">3</div>
            </div>
            <div className="team">
                <div className="team-name">Zuehlke</div>
                <div className="team-w">0</div>
                <div className="team-l">4</div>
            </div>
        </div>
      </div>
      <h2>Bracket</h2>
      <div className="wrapper__bracket">
        <div className="quarters">
            <div className="match">
                <div className="team">
                    <div className="team-name label">Quarterfinal 1</div>
                    <div className="team-w">Result</div>
                </div>
                <div className="team">
                    <div className="team-name">Ardeo Solutions</div>
                    <div className="team-w">3</div>
                </div>
                <div className="team">
                    <div className="team-name">So Ge</div>
                    <div className="team-w">11</div>
                </div>
            </div>
            <div className="match">
                <div className="team">
                    <div className="team-name label">Quarterfinal 2</div>
                    <div className="team-w">Result</div>
                </div>
                <div className="team">
                    <div className="team-name">Taraba</div>
                    <div className="team-w">9</div>
                </div>
                <div className="team">
                    <div className="team-name">Catena</div>
                    <div className="team-w">8</div>
                </div>
            </div>
            <div className="match">
                <div className="team">
                    <div className="team-name label">Quarterfinal 3</div>
                    <div className="team-w">Result</div>
                </div>
                <div className="team">
                    <div className="team-name">SWG</div>
                    <div className="team-w">13</div>
                </div>
                <div className="team">
                    <div className="team-name">Lalini Medjedi</div>
                    <div className="team-w">11</div>
                </div>
            </div>
            <div className="match">
                <div className="team">
                    <div className="team-name label">Quarterfinal 4</div>
                    <div className="team-w">Result</div>
                </div>
                <div className="team">
                    <div className="team-name">Tieto</div>
                    <div className="team-w">7</div>
                </div>
                <div className="team">
                    <div className="team-name">Nesalomivi</div>
                    <div className="team-w">12</div>
                </div>
            </div>
        </div>
        <div className="semis">
            <div className="match">
                <div className="team">
                    <div className="team-name label">Semifinal 1</div>
                    <div className="team-w">Result</div>
                </div>
                <div className="team">
                    <div className="team-name">So Ge</div>
                    <div className="team-w">9</div>
                </div>
                <div className="team">
                    <div className="team-name">Taraba</div>
                    <div className="team-w">8</div>
                </div>
            </div>
            <div className="match">
                <div className="team">
                    <div className="team-name label">Semifinal 2</div>
                    <div className="team-w">Result</div>
                </div>
                <div className="team">
                    <div className="team-name">SWG</div>
                    <div className="team-w">13</div>
                </div>
                <div className="team">
                    <div className="team-name">Nesalomivi</div>
                    <div className="team-w">21</div>
                </div>
            </div>
        </div>
        <div className="finals">
            <div className="match">
                <div className="team">
                    <div className="team-name label">Finals </div>
                    <div className="team-w">Result</div>
                </div>
                <div className="team">
                    <div className="team-name">So Ge</div>
                    <div className="team-w">5</div>
                </div>
                <div className="team">
                    <div className="team-name">Nesalomivi</div>
                    <div className="team-w">9</div>
                </div>
            </div>
            <div className="match">
                <div className="team">
                    <div className="team-name label">3rd place</div>
                    <div className="team-w">Result</div>
                </div>
                <div className="team">
                    <div className="team-name">SWG</div>
                    <div className="team-w">21</div>
                </div>
                <div className="team">
                    <div className="team-name">taraba</div>
                    <div className="team-w">16</div>
                </div>
            </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default Standings2019Page
